import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { McpService } from '../services/mcp.service';
/**
 * Check if the User ist authenticated
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  /**
   * constructor
   * @param router 
   * @param authenticationService 
   * @param mcp 
   */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private mcp: McpService
  ) { }
  /**
   * Check if the User is authenticated
   * @param route 
   * @param state 
   * @returns 
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.mcp.loadConfiguration().subscribe({
      next: (config: any) => {
        document.title = config?.portal_name + " Dashboard";
        document.getElementById('favicon_1')["href"] = config?.favicon;
        document.getElementById('favicon_2')["href"] = config?.favicon;
      },
      error: err => {
        console.log(err)
      }
    })

    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/']);
      return false;
    }



  }
}