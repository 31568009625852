import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the Reservationfilter based on the Type
 */
@Pipe({
  name: 'translateFilterReservation'
})
export class TranslateFilterReservationPipe implements PipeTransform {
/**
 * Transform the state based on the Type
 * @param value 
 * @param type 
 * @returns 
 */
  transform(value: string[], type: string): string[] {
    if (type == 'eventStates') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'DRAFT':
            if (returnArray.length == 0) {
              returnArray.push("Entwurf");
            }
            break;
          case 'PREVIEW':
            if (returnArray.length == 0) {
              returnArray.push("Vorschau");
            }
            break;
          case 'ACTIVE':
            if (returnArray.length == 0) {
              returnArray.push("Aktiv");
            }
            break;
          case 'HIDDEN':
            if (returnArray.length == 0) {
              returnArray.push("Versteckt");
            }
            break;
          case 'CANCELLED':
            if (returnArray.length == 0) {
              returnArray.push("Gestrichen");
            }
            break;
          case 'EXPIRED':
            if (returnArray.length == 0) {
              returnArray.push("Abgelaufen");
            }
            break;
          case 'ARCHIVED':
            if (returnArray.length == 0) {
              returnArray.push("Archiviert");
            }
            break;
          case 'DELETED':
            if (returnArray.length == 0) {
              returnArray.push("Gelöscht");
            }
            break;
          case 'CREATED':
            if (returnArray.length == 0) {
              returnArray.push("Aktiv");
            }
            break;
          default:
            returnArray.push(element);
            break;
        }
      });
      return returnArray
    } else if (type == 'filterByPaymentStates') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'NO_PAYMENT':
            if (returnArray.length == 0) {
              returnArray.push("Kostenlos");
            }
            break;
          case 'PENDING':
            if (returnArray.length == 0) {
              returnArray.push("Nicht bezahlt");
            }
            break;
          case 'INVOICED':
            if (returnArray.length == 0) {
              returnArray.push("Zugestellt");
            }
            break;
          case 'PAID':
            if (returnArray.length == 0) {
              returnArray.push("Bezahlt");
            }
            break;
          case 'CANCELLED':
            if (returnArray.length == 0) {
              returnArray.push("Storniert");
            }
            break;
          default:
            returnArray.push(element);
            break;
        }
      })
      return returnArray
    }else if (type == 'filterByStates') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'RESERVED':
            if (returnArray.length == 0) {
              returnArray.push("Reserviert");
            }
            break;
          case 'CANCELLED':
            if (returnArray.length == 0) {
              returnArray.push("Storniert");
            }
            break;
          case 'USED':
            if (returnArray.length == 0) {
              returnArray.push("Eingelöst");
            }
            break;
          case 'NEW':
            if (returnArray.length == 0) {
              returnArray.push("Neu");
            }
            break;
          case 'FAILED':
            if (returnArray.length == 0) {
              returnArray.push("Fehler");
            }
            break;
            case 'FAILED':
              if (returnArray.length == 0) {
                returnArray.push("Ausgelaufen");
              }
              break;
              case 'EXPIRED':
                if (returnArray.length == 0) {
                  returnArray.push("Abgelehnt");
                }
                break;
          default:
            returnArray.push(element);
            break;
        }
      })
      return returnArray
    }else if (type == 'eventPartnerPublicIds') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          returnArray.push(element['name']);
        }
      });
      return returnArray
    }


  }
}
